<template>
<div>
  <v-app-bar
      app
      clipped-left
      color="primary"
      dark
  >
    <v-img
        :src="'https://businessclub-hbc.e-systemes.fr/assets/global/img/logo/'+current_club.acronym+'-web.png'"
        max-height="50"
        max-width="45"
    ></v-img>
    <v-btn
        icon
        color="white"
        style="margin-left: -10px"
        @click="$root.drawer = !$root.drawer"
    >
      <v-icon>fa-chevron-down</v-icon>
    </v-btn>

    <v-spacer></v-spacer>

    <v-toolbar-title class="mr-3">Bonjour Visiteur</v-toolbar-title>
    <router-link to="/inscription_membre">
      <v-avatar>
        <img
            src="https://cdn.vuetifyjs.com/images/john.jpg"
            alt="John"
        >
      </v-avatar>
    </router-link>
  </v-app-bar>
  <v-navigation-drawer
      v-model="$root.drawer"
      app
      clipped
      color="primary"
      width="86"
      class="mt-100"
  >
    <router-link to="/">
      <v-img
          class="d-block text-center mx-auto mt-4"
          color="grey darken-1"
          max-width="40"
          :src="'https://businessclub-hbc.e-systemes.fr/assets/global/img/logo/'+current_club.acronym+'-web.png'"
      ></v-img>
    </router-link>

    <v-divider class="white mx-3 my-5"></v-divider>
    <a>
      <v-img
          v-for="(club, i) in clubs"
          :key="i"
          class="d-block primary text-center mx-auto mb-9"
          max-width="35"
          :src="'https://businessclub-hbc.e-systemes.fr/assets/global/img/logo/'+club.acronym+'-web.png'"
          @click="$root.switchClub(club.id)"
      ></v-img>
    </a>

    <template v-slot:append>
      <v-row class="space-around ma-5 mb-10">

      <v-btn icon to="choixclub">

        <v-icon
            large
            color="secondary"
            class="text-center"
        >
          mdi-plus-circle
        </v-icon>
      </v-btn>
      </v-row>
    </template>
  </v-navigation-drawer>

</div>
</template>

<script>
export default {
  name: `Header`,
  computed: {
    current_club: function(){
      let $scope = this;
      let default_club = {
          acronym: "hbc"
      }

      let club = $scope.$root.DATAS.clubs.filter(club => $scope.$root.current_club_id == club.id)[0];
      // eslint-disable-next-line no-console
      //console.debug("TEST", $scope.$root.DATAS.clubs.filter(club => $scope.$root.current_club_id == club.id));
      return club != undefined ? club : default_club;
    },
    clubs: function () {

      let $scope = this;

      return $scope.$root.DATAS.clubs.filter(club => $scope.$root.connected_clubs_ids.includes(club.id));
    }
  },
  data: () => ({
  }),
}
</script>

<style scoped>
</style>