<template>
  <v-bottom-navigation
      class="primary"
      grow
      app
      height="70"
  >

    <v-btn
        v-for="onglet in onglets"
        x-small :to="onglet.to"
        :key="onglet.nom"
        color="primary"
        height="100%"
        class="white--text mt-1"
    >
      <span class="text-uppercase mt-2 footer-color white--text">{{ onglet.nom }}</span>
      <v-icon color="white">{{ onglet.icone }}</v-icon>
    </v-btn>

  </v-bottom-navigation>
</template>

<script>
export default {
  name: `Footer`,
  data: function () {
    return {
      onglets: [
        {"nom" : "L'agenda", "icone": "fa-utensils", "to": "/programmation"},
        {"nom" : "Actualités", "icone": "fa-newspaper", "to": "/actualites"},
        {"nom" : "Membres", "icone": "fa-users", "to": "/societes"},
        {"nom" : "Business", "icone": "fa-suitcase", "to": "/annonces"},
        {"nom" : "Mon compte", "icone": "fa-user", "to": "/espace_membre"},
      ],
    }
  }
}
</script>

<style scoped>
</style>