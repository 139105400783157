<template>
  <v-app>
    <Header v-if="$router.currentRoute.name != 'Lancement'"/>

    <v-main>
        <v-responsive style="height: 100%">
          <router-view/>
        </v-responsive>
    </v-main>

    <v-footer app style="padding: 0px !important;">
      <Footer v-if="$router.currentRoute.name != 'Lancement'"/>
    </v-footer>
  </v-app>
</template>

<script>

// On importe le Header et le footer.
import Footer from "./components/Footer";
import Header from "./components/Header";

export default {
  name: 'App',
  data: () => ({
    value: 'music',
    color: 'indigo',
  }),
  components: {
    Footer,
    Header,
  },
};
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Barlow:wght@100&display=swap');
*{
  font-family: 'Barlow Condensed', sans-serif;
}

.footer-color{
  font-size: smaller;
  letter-spacing: initial;
}

.border-app{
  border-bottom: none;
  border-style: outset;
  border-color: #02263C;
  border-width: 6px !important;
}
</style>