<template>
    <!-- Arrière plan - Image -->
    <v-img
        src="https://www.ffc.fr/app/uploads/sites/3/2019/06/cycliste-homepage-profil.jpg"
        position="center"
        class="fill-height"
        :max-height="height"
    >
      <!-- Zone de texte -->
      <v-container style="position: fixed !important; bottom: calc(3% + 70px) !important;">

        <div class="bg-secondary-transparent white--text text-uppercase">
          <span style="font-size: x-large"><b>18 mai 2021</b></span>
          <span> <b> &nbsp;à la cour de honau - la wantzenau </b></span>
        </div>

        <v-container class="bg-sombre-transparent">
        <div>
          <div>
            <h2 class="secondary--text pb-2">
              Pierre-Emmanuel <br>
              <b class="text-uppercase">bonneville-morawski</b>
            </h2>

            <h3 class="white--text pb-2">
              Ancien ministre de l'intérieur, Ancien président de l'Assemblée Nationale et du conseil Constitutionel
            </h3>
            <p class="white--text" style="font-size: smaller">
              "Comme une entreprise, le RAID s'adapte à chaque instant pour tendre vers l'excellence. C'est une aptitude qui a permis le succès et la libération de tous les otages de l'Hypercacher de Vincennes. "
            </p>

            <v-btn color="secondary black--text" to="dejeuner">
              <b>Je souhaite participer à cet événement</b>
            </v-btn>
          </div>
        </div>
        </v-container>
      </v-container>
    </v-img>
</template>

<script>

export default {
  name: `Accueil`,
  data: function () {
    return {
    }
  },
  computed: {
    height(){
      // Taille écran - taille header - taille footer.
      return window.window.innerHeight - 56 - 70;
    }
  }
}
</script>

<style scoped>

.bg-secondary-transparent {
  background: rgba(202, 191, 166, .45);
}

.bg-sombre-transparent {
  background: linear-gradient(
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.5)
  )
}

</style>