import Vue from 'vue'
import './plugins/fontawesome'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import DATAS from './assets/datas.json';
import axios from 'axios';
var VueCookie = require('vue-cookie');

Vue.config.productionTip = false
Vue.use(VueCookie);

/** Vue Filters Start */
// look = https://stackoverflow.com/questions/35070271/vue-js-components-how-to-truncate-the-text-in-the-slot-element-in-a-component
Vue.filter('truncate', function (text, length, suffix) {
  if (text.length > length) {
    return text.substring(0, length) + suffix;
  } else {
    return text;
  }
});

new Vue({
  router,
  vuetify,
  render: h => h(App),
  data: () => ({
    DATAS,
    splash_already: true,
    jwt_token: null,
    current_club_id: null,
    connected_clubs_ids: [],
    drawer: false,
    convives: [],
    logged_in_app: false,
  }),
  created(){
    this.$cookie.set('DEBUG', 'TRUE', 1);
    this.test();

    setTimeout(this.getJwtToken, 4000);
  },
  methods: {

    switchClub(club_id){
      this.drawer = false;
      this.current_club_id = club_id;
      this.$router.push("/");
    },

    followAClub(club_id){

      this.connected_clubs_ids.push(club_id);
      this.switchClub(club_id);

    },
    getJwtToken(){

      let $scope = this;

      let credentials = {
        "username":"msi",
        "password":"MSI-2022-7GDF9RTX9"
      };

      //headers: { 'Content-Type': 'multipart/form-data'},

      // eslint-disable-next-line no-console
      axios.post("https://businessclub-api.e-systemes.fr/api/authentication_token", credentials).then(res => $scope.jwt_token = res.data.token).then($scope.getSocieties.bind(null, undefined)).then($scope.getClubs.bind(null, undefined));

    },
    getClubs(page="/api/clubs?page=1"){
      let $scope = this;

      let headers = {
        headers: {
          Authorization: `Bearer ` + $scope.jwt_token
        }
      };

      // eslint-disable-next-line no-console
      //console.log("TEST", page);

      // eslint-disable-next-line no-console
      axios.get("https://businessclub-api.e-systemes.fr" + page, headers).then(function(res){
        res.data['hydra:member'].forEach(element => $scope.DATAS.clubs.push(element));
        $scope.current_club_id = $scope.$root.DATAS.clubs[0].id;
        if(res.data['hydra:view']['hydra:next']){
          $scope.getClubs(res.data['hydra:view']['hydra:next'])
        }
      });


    },
    getLunch(page="/api/societies?page=1") {
      // eslint-disable-next-line no-console
      console.debug("PAGE", page);
    },
      getSocieties(page="/api/societies?page=1"){

      let $scope = this;

      let headers = {
        headers: {
          Authorization: `Bearer ` + $scope.jwt_token
        }
      };

      // eslint-disable-next-line no-console
      //console.log("TEST", page);

      // eslint-disable-next-line no-console
      axios.get("https://businessclub-api.e-systemes.fr" + page, headers).then(function(res){
        res.data['hydra:member'].forEach(element => $scope.DATAS.societes.push(element));
        if(res.data['hydra:view']['hydra:next']){
          //$scope.getSocieties(res.data['hydra:view']['hydra:next'])
        }
      });
    },
    test(){

      // eslint-disable-next-line no-console
      console.log("YO", this.splash_already);
      if(!this.splash_already){
        this.splash_already= true;
        this.$router.push("lancement");
        setTimeout(this.test, 3000);
      }else{
        this.$router.push("/");
      }
    }
  }
}).$mount('#app')
